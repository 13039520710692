<template>
  <div class="main">
    <div class="card p-3">
      <div class="row card-body">
        <!-- General -->
        <div class="col-xl-5 col-lg-12 mb-2">
          <h5>{{ $t('general') }}</h5>
        </div>
        <div class="col-xl-7 col-lg-12">
          <a-form-model
            ref="general"
            :model="form">
            <j-input
              prop="ref"
              v-model="form.ref"
              icon="number"
              read-only />
            <j-enum-select
              prop="size"
              v-model="form.size"
              :rules="[v.required]" />
            <j-input
              prop="value"
              v-model="form.value"
              icon="dollar" />
            <j-input
              prop="notes"
              v-model="form.notes"
              icon="form" />
          </a-form-model>
        </div>
        <a-divider />
        <!-- Origin -->
        <div class="col-xl-5 col-lg-12 mb-2">
          <h5>{{ $t('origin') }}</h5>
        </div>
        <div class="col-xl-7 col-lg-12">
          <a-form-model
            ref="sender"
            :model="form.origin.contact">
            <j-input
              prop="name"
              v-model="form.origin.contact.name"
              icon="user"
              :rules="[v.required]" />
            <j-input
              prop="phone"
              v-model="form.origin.contact.phone"
              :format="f.phone"
              icon="phone" />
            <j-input
              prop="email"
              v-model="form.origin.contact.email"
              :format="f.lower"
              icon="mail" />
            <j-input
              prop="identification"
              v-model="form.origin.contact.identification"
              icon="idcard" />
          </a-form-model>
          <a-form-model
            ref="origin"
            :model="form.origin.address">
            <j-input
              prop="street"
              v-model="form.origin.address.street"
              icon="environment"
              :rules="[v.required]" />
            <j-input
              prop="apartment"
              v-model="form.origin.address.apartment"
              icon="bell" />
            <j-zip
              prop="zip"
              v-model="form.origin.address.zip"
              icon="global"
              :help="$t('msg.zone.autocomplete')"
              @change="onOriginZoneChange"
              :rules="[v.required]"/>
            <j-input
              prop="city"
              v-model="form.origin.address.city"
              icon="global"
              :rules="[v.required]" />
            <j-input
              prop="state"
              v-model="form.origin.address.state"
              icon="global"
              :rules="[v.required]" />
          </a-form-model>
        </div>
        <a-divider />
        <!-- Destination -->
        <div class="col-xl-5 col-lg-12 mb-2">
          <h5>{{ $t('destination') }}</h5>
        </div>
        <div class="col-xl-7 col-lg-12">
          <a-form-model
              ref="receiver"
              :model="form.destination.contact">
            <j-input
              prop="name"
              v-model="form.destination.contact.name"
              icon="user"
              :rules="[v.required]" />
            <j-input
              prop="phone"
              v-model="form.destination.contact.phone"
              :format="f.phone"
              icon="phone" />
            <j-input
              prop="email"
              v-model="form.destination.contact.email"
              :format="f.lower"
              icon="mail" />
            <j-input
              prop="identification"
              v-model="form.destination.contact.identification"
              icon="idcard" />
          </a-form-model>
          <a-form-model
            ref="destination"
            :model="form.destination.address">
            <j-input
              prop="street"
              v-model="form.destination.address.street"
              icon="environment"
              :rules="[v.required]" />
            <j-input
              prop="apartment"
              v-model="form.destination.address.apartment"
              icon="bell" />
            <j-zip
              prop="zip"
              v-model="form.destination.address.zip"
              icon="global"
              :help="$t('msg.zone.autocomplete')"
              @change="onDestinationZoneChange"
              :rules="[v.required]"/>
            <j-input
              prop="city"
              v-model="form.destination.address.city"
              icon="global"
              :rules="[v.required]" />
            <j-input
              prop="state"
              v-model="form.destination.address.state"
              icon="global"
              :rules="[v.required]" />
          </a-form-model>
        </div>
        <!-- Submit -->
        <div class="col-xl-5 col-lg-12 mb-2" />
        <div class="col-xl-7 col-lg-12">
          <div class="font-size-18 mt-4">
            <a-button
              @click="onSubmit"
              type="primary"
              size="large">
              {{ $t('msg.shipment.update') }}
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import v from '@/utils/validators'
import f from '@/utils/formatters'
import acl from '@/auth/acl'
import lms from '@/api/lms'
import JInput from '@/views/shared/forms/input'
import JZip from '@/views/shared/forms/zip'
import JEnumSelect from '@/views/shared/forms/enum-select'
export default {
  components: {
    JInput,
    JZip,
    JEnumSelect,
  },
  data: function () {
    return {
      v,
      f,
      acl,
      form: {
        origin: {
          contact: {},
          address: {},
        },
        destination: {
          contact: {},
          address: {},
        },
      },
    }
  },
  computed: {
    id() {
      return this.$route.params.shipment
    },
  },
  mounted() {
    lms.shipment.fetchDetail(this.id)
      .then(response => {
        const shipment = response.data
        this.form.ref = shipment.ref
        this.form.size = shipment.size
        this.form.value = shipment.value
        this.form.notes = shipment.notes
        this.form.origin.contact = shipment.origin.contact || {}
        this.form.origin.address = shipment.origin.address
        this.form.destination.contact = shipment.destination.contact || {}
        this.form.destination.address = shipment.destination.address
      })
  },
  methods: {
    onOriginZoneChange(zone) {
      this.form.origin.address.city = zone.meta.city
      this.form.origin.address.state = zone.meta.state
    },
    onDestinationZoneChange(zone) {
      this.form.destination.address.city = zone.meta.city
      this.form.destination.address.state = zone.meta.state
    },
    onReset() {
      this.form.origin.address.lat = null
      this.form.origin.address.lng = null
      this.form.destination.address.lat = null
      this.form.destination.address.lng = null
    },
    onSubmit() {
      Promise.all([
        new Promise((resolve, reject) => this.$refs.general.validate(resolve)),
        new Promise((resolve, reject) => this.$refs.sender.validate(resolve)),
        new Promise((resolve, reject) => this.$refs.origin.validate(resolve)),
        new Promise((resolve, reject) => this.$refs.receiver.validate(resolve)),
        new Promise((resolve, reject) => this.$refs.destination.validate(resolve)),
      ]).then(results => {
        if (results.every(Boolean)) {
          this.onReset()
          lms.shipment.update(this.id, this.form)
            .then(_ => this.$router.push('detail'))
        }
      })
    },
  },
}
</script>
